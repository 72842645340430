import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-polyglot';
import { graphql } from 'gatsby';

import withSetLocale from '../../../components/i18n/withSetLocale';

import Layout from '../../../components/layout';
import DeveloperPage from '../../../components/Jobs/DeveloperPage';

const Developer = ({ data, pageContext: { locale, messages } }) => (
    <Layout>
        <I18n locale={locale} messages={messages}>
            <DeveloperPage locale={locale} data={data} />
        </I18n>
    </Layout>
);

Developer.propTypes = {
    data: PropTypes.object.isRequired,
    pageContext: PropTypes.object.isRequired,
};

export default withSetLocale()(Developer);

export const query = graphql`
    query DeveloperQuery {
        headerImage: file(relativePath: { eq: "jobs/developer/setup.jpg" }) {
            publicURL
            absolutePath
            name
            childImageSharp {
                fluid(maxWidth: 1280, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }

        cardImages: allFile(
            filter: {
                sourceInstanceName: { eq: "images" }
                absolutePath: { glob: "**/jobs/developer/cards/**" }
            }
        ) {
            edges {
                node {
                    publicURL
                    absolutePath
                    name
                    childImageSharp {
                        # it looked ugly without quality 100
                        fluid(maxWidth: 200, quality: 100) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        }
    }
`;
