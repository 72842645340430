import React from 'react';
import PropTypes from 'react-proptypes';
import styled from 'styled-components';
import { translate } from 'react-polyglot';
import compose from 'recompose/compose';
import Helmet from 'react-helmet';
import mapProps from 'recompose/mapProps';

import AppBar from '../AppBar';

import Footer from '../Footer';
import MaxWidthContainer from '../MaxWidthContainer';
import TopBar from '../TopBar';
import CardBase from '../Card';
import { PrimaryLink } from '../Button';
import BaseViewMore from '../ViewMore';
import WithBackgroundImage from '../Image/WithBackgroundImage';
import HeaderImage from '../HeaderImage';
import defaultTheme from '../theme';

import { NodeJsLogo, ReactLogo, GraphQLLogo, PgLogo, AwsLogo } from '../Icons';

/* eslint-disable import/no-named-as-default-member */
const { Card: BaseCard, CardTitle: BaseCardTitle, CardImage } = CardBase;
/*  eslint-enable import/no-named-as-default-member */

const Container = styled.div`
    background-color: ${({ theme }) => theme.gray}
    display: flex;
    flex-direction: column;
`;

const PresentationContainer = styled.div`
    padding: 1.875rem;
    background-color: white;
    max-width: 720px;
    margin: 0 auto 2rem auto;
    border-bottom: solid 1px ${({ theme }) => theme.shadowColor};
    @media (max-width: 600px) {
        position: static;
        width: auto;
        height: auto;
        padding: 1em;
    }
`;

const TextContainer = styled.div`
    margin-top: 0;
    padding: 0.625rem;
    > p:first-child {
        margin-top: 0;
    }
`;

const SubTitle = styled.h2`
    font-family: 'Montserrat', sans-serif;
    font-size: 2.5rem;
    line-height: 3rem;
    font-weight: 300;
    margin-top: 3rem;
    margin-bottom: 2rem;
    text-align: center;
    text-transform: uppercase;
    color: ${({ theme }) => theme.headlinesColor};

    @media (max-width: 600px) {
        font-size: 1.5rem;
        line-height: 2rem;
    }
`;

const Text = styled.div`
    max-width: 720px;
    text-align: center;
    margin: auto;
    margin-bottom: 2rem;
`;

const GridContainer = styled(MaxWidthContainer)`
    display: flex;
    justify-content: center;
    margin: 0 0 1rem 0;
    flex-direction: row;
    flex-wrap: wrap;

    > div {
        flex-basis: ${({ basis }) => basis || 'auto'}%;
        flex-grow: 1;
    }
`;

const Card = styled(BaseCard)`
    background-color: ${({ theme, opaque }) =>
        opaque ? theme.white : 'transparent'};
    ${({ theme, opaque }) =>
        opaque ? `border-bottom: 1px solid ${theme.shadowColor}` : null};
    margin: 0 0.5rem 0.5rem;
    max-width: 200px;
    min-width: ${({ minWidth }) => minWidth || '150px'};
`;

const CardTitle = styled(BaseCardTitle)`
    font-size: 0.9rem;
    text-align: center;
    line-height: 1rem;
    margin: 0.5rem 0;
`;

const ViewMore = props => <BaseViewMore {...props} color={defaultTheme.blue} />;

const BlogCardImage = styled(WithBackgroundImage)`
    text-align: center;
    height: 80px;
    overflow: hidden;
    z-index: 0;
`;

const stack = [
    { name: 'react-redux', icon: <ReactLogo /> },
    { name: 'nodejs', icon: <NodeJsLogo /> },
    { name: 'graphql', icon: <GraphQLLogo /> },
    { name: 'postgresql', icon: <PgLogo /> },
    { name: 'aws', icon: <AwsLogo /> },
];

const ownership = [
    {
        name: 'redux',
        icon: 'redux-or-not',
        link: '/blog/2018/06/27/redux-or-not-redux.html',
    },
    {
        name: 'slider-poll',
        icon: 'react-slider-poll',
        link: '/blog/2018/10/04/react-slider-poll.html',
    },
    {
        name: 'accessibility',
        icon: 'accessibility',
        link:
            '/blog/2018/07/18/accessibility-performance-testing-puppeteer.html',
    },
];

const conferences = [
    {
        name: 'blend',
        icon: 'blend-web-mix',
        link: '/blog/2018/11/02/blendwebmix-2018.html',
    },
    {
        name: 'devfest',
        icon: 'devfest',
        link: '/blog/2018/10/22/devfest-nantes-2018.html',
    },
    {
        name: 'dotai',
        icon: 'dotai',
        link: '/blog/2018/07/12/humans-learning-machine-learning-at-dotai.html',
    },
];

const hackdays = [
    {
        name: 'detox',
        icon: 'detox',
        link:
            '/blog/2018/06/05/get-rid-of-toxic-bugs-on-your-apps-with-detox.html',
    },
    {
        name: 'memory-leaks',
        icon: 'cookie-monster',
        link:
            '/blog/2018/04/03/how-to-track-and-fix-memory-leak-with-nodejs.html',
    },
    {
        name: 'functional-programming',
        icon: 'monad-functor-small',
        link: '/blog/2018/09/26/functional-programming-3-functor-redone.html',
    },
];

const Developer = ({ headerImage, cardImages, locale, t }) => (
    <Container>
        <Helmet title={t('seo.jobTitle')}>
            <html lang={locale} />
            <meta name="description" content={t('seo.jobDescription')} />
        </Helmet>
        <TopBar white role="presentation" />
        <header>
            <AppBar />
            <HeaderImage image={headerImage} />
        </header>
        <MaxWidthContainer role="main" width={1024}>
            <SubTitle
                dangerouslySetInnerHTML={{
                    __html: t('jobs.developer.title'),
                }}
            />
            <PresentationContainer>
                <TextContainer
                    dangerouslySetInnerHTML={{
                        __html: t('jobs.developer.intro'),
                    }}
                />
            </PresentationContainer>
            <SubTitle
                dangerouslySetInnerHTML={{
                    __html: t('jobs.developer.stack.title'),
                }}
            />
            <MaxWidthContainer width={850}>
                <Text
                    dangerouslySetInnerHTML={{
                        __html: t('jobs.developer.stack.intro'),
                    }}
                />
            </MaxWidthContainer>
            <GridContainer>
                {stack.map(({ name, icon }) => (
                    <Card key={name} opaque minWidth={'auto'}>
                        <CardImage>{icon}</CardImage>
                        <CardTitle>
                            {t(`jobs.developer.stack.${name}.name`)}
                        </CardTitle>
                    </Card>
                ))}
            </GridContainer>
            <SubTitle
                dangerouslySetInnerHTML={{
                    __html: t('jobs.developer.agility-and-communication.title'),
                }}
            />
            <MaxWidthContainer width={850}>
                <Text
                    dangerouslySetInnerHTML={{
                        __html: t(
                            'jobs.developer.agility-and-communication.intro',
                        ),
                    }}
                />
            </MaxWidthContainer>
            <SubTitle
                dangerouslySetInnerHTML={{
                    __html: t('jobs.developer.ownership.title'),
                }}
            />
            <MaxWidthContainer width={850}>
                <Text
                    dangerouslySetInnerHTML={{
                        __html: t('jobs.developer.ownership.intro'),
                    }}
                />
            </MaxWidthContainer>
            <GridContainer basis={40}>
                {ownership.map(({ name, icon, link }) => (
                    <Card key={name} opaque>
                        <BlogCardImage image={cardImages[icon]} />
                        <CardTitle>
                            {t(`jobs.developer.ownership.${name}.name`)}
                        </CardTitle>
                        <ViewMore
                            label={t(`jobs.apply.link`)}
                            href={link}
                            centered
                        />
                    </Card>
                ))}
            </GridContainer>
            <SubTitle
                dangerouslySetInnerHTML={{
                    __html: t('jobs.developer.lookout.title'),
                }}
            />
            <MaxWidthContainer width={850}>
                <Text
                    dangerouslySetInnerHTML={{
                        __html: t('jobs.developer.lookout.intro'),
                    }}
                />
            </MaxWidthContainer>
            <GridContainer basis={40}>
                {conferences.map(({ name, icon, link }) => (
                    <Card key={name} opaque>
                        <BlogCardImage image={cardImages[icon]} />
                        <CardTitle>
                            {t(
                                `jobs.developer.lookout.conferences.${name}.name`,
                            )}
                        </CardTitle>
                        <ViewMore
                            label={t(`jobs.apply.link`)}
                            href={link}
                            centered
                        />
                    </Card>
                ))}
            </GridContainer>
            <MaxWidthContainer width={850}>
                <Text
                    dangerouslySetInnerHTML={{
                        __html: t('jobs.developer.lookout.intro2'),
                    }}
                />
            </MaxWidthContainer>
            <GridContainer basis={40}>
                {hackdays.map(({ name, icon, link }) => (
                    <Card key={name} opaque>
                        <BlogCardImage image={cardImages[icon]} />
                        <CardTitle>
                            {t(`jobs.developer.lookout.hackdays.${name}.name`)}
                        </CardTitle>
                        <ViewMore
                            label={t(`jobs.apply.link`)}
                            href={link}
                            centered
                        />
                    </Card>
                ))}
            </GridContainer>
            <SubTitle
                dangerouslySetInnerHTML={{
                    __html: t('jobs.developer.apply.title'),
                }}
            />
            <PresentationContainer>
                <TextContainer
                    dangerouslySetInnerHTML={{
                        __html: t('jobs.developer.apply.intro'),
                    }}
                />
            </PresentationContainer>
            <Text
                dangerouslySetInnerHTML={{
                    __html: t('jobs.developer.apply.outro'),
                }}
            />
            <div
                style={{
                    textAlign: 'center',
                    margin: '0.5rem auto 5rem',
                }}
            >
                <PrimaryLink href="mailto:contact@marmelab.com?subject=Interested in a developer position at marmelab">
                    {t('aboutus.contact_us')}
                </PrimaryLink>
            </div>
        </MaxWidthContainer>
        <Footer />
    </Container>
);

Developer.propTypes = {
    locale: PropTypes.string,
    t: PropTypes.func,
    headerImage: PropTypes.object,
    cardImages: PropTypes.object,
};

export default compose(
    mapProps(({ data, locale }) => {
        return {
            locale,
            headerImage: data.headerImage,
            cardImages: data.cardImages.edges.reduce((acc, { node }) => {
                acc[node.name] = node;
                return acc;
            }, {}),
        };
    }),
    translate(),
)(Developer);
